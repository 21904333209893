import { call, delay, put, select } from 'redux-saga/effects';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../../actionsTypes/tabs.actionTypes';
import { workspaceRemoveTab } from '../../actions/tabs.actions';
import { TWorkspaceTabsRemoveRequestAction } from '../../actions/tabs.actions.types';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { SAVE_MODEL } from '../../actionsTypes/save.actionTypes';
import { TSaveModelAction, TSaveModelPayload } from '../../actions/save.actions.types';
import { ModelNode } from '../../serverapi/api';
import { IModelNode } from '../../models/bpm/bpm-model-impl.types';
import { navigatorClearProperties } from '../../actions/navigatorProperties.actions';
import { getLockingTool, SaveModelLockTool } from '../../modules/Editor/classes/SaveModelLockTool';
import { modelRequestSuccess } from '../../actions/model.actions';
import { EditorMode } from '../../models/editorMode';
import { unlock } from '../../actions/lock.actions';
import { clearStateComments, closeCommentsPanel, deleteEditingComment } from '../../actions/comments.actions';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { ModelSelectors } from '../../selectors/model.selectors';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { modelService } from '../../services/ModelService';
import { TWorkspaceTab } from '@/models/tab.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';
import { MEDIUM_NOTIFICATION_DURATION, NotificationType } from '@/models/notificationType';
import { LocalesService } from '@/services/LocalesService';
import messages from '@/modules/App/messages/AppNotifications.messages';
import { notification } from 'antd';
import { showNotificationByType } from '@/actions/notification.actions';
import { formatPanelClose } from '../../actions/formatPanel.actions';
import { takeEverySafely } from '../sagaHelpers';

function* handleTabCloseRequest(action: TWorkspaceTabsRemoveRequestAction) {
    const tabNodeId = action.payload.workspaceTab.nodeId;
    const workspaceTab: TWorkspaceTab = yield select(TabsSelectors.byId(tabNodeId));

    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(tabNodeId); // todo keep by nodeId

    // Завершаем редактирование имени у текстового блока и символа на графе
    if (graph) {
        graph.stopEditing(false);
    }

    if (workspaceTab?.content?.type === TreeItemType.Model) {
        const lock: SaveModelLockTool = getLockingTool();

        if (!lock.isLocked(tabNodeId.id)) {
            yield call(saveWithRetry, {
                graphId: tabNodeId,
                forceSave: true,
                forceSaveHistory: true,
                attempts: 0,
                showNotification: true,
            });
        }

        yield put(navigatorClearProperties());

        yield put(deleteEditingComment(tabNodeId));
        yield put(closeCommentsPanel({ modelId: tabNodeId }));
        yield put(formatPanelClose(tabNodeId));
        yield put(clearStateComments(tabNodeId));

        if (workspaceTab.mode === EditorMode.Edit) {
            yield put(unlock(tabNodeId, 'MODEL'));
        }

        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* saveWithRetry({ graphId, forceSave, forceSaveHistory, attempts, showNotification }: TSaveModelPayload) {
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(graphId);

    if (!graph) {
        return;
    }

    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(graphId));
    const locale: Locale = yield select(getCurrentLocale);
    const model: IModelNode = yield select(ModelSelectors.byId(graphId));
    const isDefaultGraph = graph instanceof DefaultGraph;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const lock: SaveModelLockTool = getLockingTool();

    // может быть ситуация когда пользователь нажал ctrl+s параллельно с автосохраненением, не хотелось бы ему сразу показывать ошибку
    // поэтому при нажатии ctrl+s у модели будет две попытки сохраниться
    //
    // если модель заблокирована (не путать с блокровкой модели на изменение, значек замка) значит идет процесс сохранения
    // если попыток сохраненения несколько, ждем 1,5с и запускаем сохранение повторно
    // если попытка сохранения должна быть одна, то покакзываем сообщение об ошибке и звершаем выполнение
    if (lock.isLocked(graphId.id)) {
        if (attempts > 0) {
            yield delay(1500);
            yield saveWithRetry({
                graphId,
                forceSave,
                forceSaveHistory,
                attempts: attempts - 1,
                showNotification,
            });
            return;
        } else {
            yield put(showNotificationByType(NotificationType.LOW_SERVER));
            return;
        }
    }

    const savedModel: ModelNode | undefined = yield call(() =>
        modelService().prepareAndSaveModel(graph, isDefaultGraph, model, tab, locale, forceSave, forceSaveHistory),
    );

    if (savedModel) {
        yield put(modelRequestSuccess(savedModel.nodeId.serverId, savedModel));
    }

    if (savedModel && showNotification) {
        notification.success({
            message: intl.formatMessage(messages.modelSaveSuccess),
            duration: MEDIUM_NOTIFICATION_DURATION,
        });
    }

    return savedModel;
}

function* handleSaveModelRequest({ payload }: TSaveModelAction) {
    const { graphId, forceSave, forceSaveHistory, attempts, showNotification } = payload;
    yield call(saveWithRetry, {
        graphId,
        forceSave,
        forceSaveHistory,
        attempts,
        showNotification,
    });
}

export function* saveModelSagaInit() {
    yield takeEverySafely(WORKSPACE_TABS_REMOVE_REQUEST, handleTabCloseRequest);
    yield takeEverySafely(SAVE_MODEL, handleSaveModelRequest);
}
