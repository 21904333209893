import { Checkbox, Form } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import { SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { getIsEnLocaleAllowed } from '../../../../../selectors/app.selector';
import { GreetingText } from './GreetingText.component';
import { NotificationType } from '@/models/notificationType';
import { showNotification, showNotificationByType } from '@/actions/notification.actions';
import { RcFile, UploadRequestOption } from 'rc-upload/lib/interface';
import { checkFileSize } from '@/utils/formatBytes.utils';
import { UserLogoUpload } from './UserLogoUpload.component';
import { isSvgImage } from '@/utils/image.utils';

export const InterFaceSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { formRef } = props;
    const isEnLocaleAllowed: boolean = useSelector(getIsEnLocaleAllowed);

    const updateGreetingLogo = (value: string | Blob | RcFile) => {
        formRef.current?.setFieldValue(SystemPropertiesFormItemNames.greetingLogo, value);
    };

    const isOpenNavigator = useSelector(SystemPropertiesSelectors.getIsOpenNavigator);
    const isCompactMainMenu = useSelector(SystemPropertiesSelectors.getIsCompactMainMenu);
    const isOpenMainMenu = useSelector(SystemPropertiesSelectors.getIsOpenMainMenu);
    const greetingLogo: string | undefined = useSelector(SystemPropertiesSelectors.getIsGreetingLogo);
    const [logo, setLogo] = useState<string | undefined>(greetingLogo);

    const onDropFile = (fileBase64: string) => {
        setLogo(fileBase64);
    };

    const beforeUpload = (file: RcFile) => {
        const isImage: boolean = isSvgImage(file);

        if (checkFileSize(file)) {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.MAX_SIZE_FOR_FILE,
                    data: { message: `${intl.formatMessage(messages.fileMaxSize)}` },
                }),
            );

            return false;
        }
        if (!isImage) {
            dispatch(showNotificationByType(NotificationType.NOT_SVG_IMAGE_ERROR));
        }

        return isImage;
    };

    const onDeleteFile = () => {
        updateGreetingLogo('');
        setLogo(undefined);
    };

    const dropFile = (e: UploadRequestOption) => {
        if (e.file) {
            updateGreetingLogo(e.file);
            const reader: FileReader = new FileReader();
            reader.readAsDataURL(e.file as File);
            reader.onloadend = () => {
                onDropFile(reader.result as string);
            };
        }
    };

    const getValueFromEvent = (e) => {
        if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
            return '';
        }

        const file: File = e.target.files[0];

        if (!isSvgImage(file)) {
            return '';
        }

        return file;
    };

    return (
        <div className={theme.formTab}>
            <div className={theme.formItemWrapper}>
                <div className={theme.wrapperColumn}>
                    <Form.Item
                        initialValue={isOpenNavigator}
                        name={SystemPropertiesFormItemNames.isOpenNavigator}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isOpenNavigator)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isCompactMainMenu}
                        name={SystemPropertiesFormItemNames.isCompactMainMenu}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isCompactMainMenu)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isOpenMainMenu}
                        name={SystemPropertiesFormItemNames.isOpenMainMenu}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isOpenMainMenu)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isEnLocaleAllowed}
                        name={SystemPropertiesFormItemNames.isEnLocaleAllowed}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isChangeLocaleAllowed)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                </div>
            </div>
            <GreetingText formRef={formRef} />
            <UserLogoUpload
                logo={logo}
                greetingLogo={greetingLogo}
                getValueFromEvent={getValueFromEvent}
                onDeleteFile={onDeleteFile}
                beforeUpload={beforeUpload}
                dropFile={dropFile}
            />
        </div>
    );
};
