import React, { useState } from 'react';
import { DatePicker, Menu, Select, TimePicker } from 'antd';
import {
    KanbanBoardPlacingConditionCondition,
    KanbanBoardPlacingRuleSearchArea,
} from '../../../../../../../models/kanban.types';
import { useIntl } from 'react-intl';
import { openDialog } from '../../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../../../modules/DialogRoot/DialogRoot.constants';
import theme from './AutomationTab.scss';
import icArrowUp from '../../../../../../../resources/icons/ic-arrow-full-up.svg';
import icArrowDown from '../../../../../../../resources/icons/ic-arrow-full-down.svg';
import icDelete from '../../../../../../../resources/icons/ic-delete-blue.svg';
import icAdd from '../../../../../../../resources/icons/ic-kanban-add.svg';
import icConditionDelete from '../../../../../../../resources/icons/ic-kanban-delete.svg';
import icCopy from '../../../../../../../resources/icons/ic-kanban-copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import UserLogin from '../../../../../../../modules/ObjectPropertiesDialog/components/PrincipalAttributeType/UserLogin.component';
import GroupName from '../../../../../../../modules/ObjectPropertiesDialog/components/PrincipalAttributeType/GroupName.component';
import { get, uniq } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import dayjs, { Dayjs } from 'dayjs';
import {
    dateTimeFormat,
    momentDateToTimestamp,
    dateFormat,
    timestampToMomentDate,
} from '../../../../../../../utils/date.time.utils';
import { ObjectTypeSelectors } from '../../../../../../../selectors/objectType.selectors';
import { PrincipalsSelectors } from '../../../../../../../selectors/principals.selectors';
import {
    formatUserName,
    checkBooleanValue,
    getUsersSelectedData,
    getGroupsSelectedData,
    checkAttributeValues,
    jsonParse,
    storageValueToString,
} from '../../../../../../ObjectPropertiesDialog/components/utils';
import {
    AttributeType,
    AttributeValue,
    AttributeValueMultiSelect,
    AttributeValueNode,
    AttributeValuePrincipal,
    AttributeValueString,
    AttributeValueUrl,
    InternationalString,
    Symbol,
    Node,
    KanbanBoardPlacingCondition,
    KanbanBoardPlacingRule,
    KanbanBoardType,
    KanbanCardType,
} from '../../../../../../../serverapi/api';
import { TPeriodRange } from '../../../../../../../utils/date.time.utils.types';
import {
    addKanbanModelTypePlacingRule,
    changeKanbanModelTypePlacingRule,
    deleteKanbanModelTypePlacingRule,
    moveKanbanModelTypePlacingRule,
} from '../../../../../../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions';
import { getAntdPopupContainer } from '../../../../../../../utils/getAntdPopupContainer';
import { SelectInfo } from 'rc-menu/lib/interface';
import { AttributeValueType } from '../../../../../../../modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { TValueTypeEnum } from '../../../../../../../models/ValueTypeEnum.types';
import { asAttributeValue } from '../../../../../../../services/api/mappers.utils';
import { Locale } from '../../../../../../../modules/Header/components/Header/header.types';
import { EditOutlined } from '@ant-design/icons';
import adminToolTheme from '../../../../../style/AdminTools.scss';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { TInputTypeEnum } from '../../../../../../UIKit/components/EditableText/EditableText.types';
import { openAttributeLinkAction } from '../../../../../../../actions/openAttributeLink.actions';
import messages from '../KanbanModelType.messages';
import { MultiLangEditableText } from '../../../../../../UIKit/components/MultiLangEditableText/MultiLangEditableText.component';
import { TAttributeUrlValues } from '../../../../../../ObjectPropertiesDialog/components/AttributeTab.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

type TAutomationPlacingProps = {
    modelType: KanbanBoardType;
    serverId: string;
    presetId: string;
    modelTypeSymbols: Symbol[];
    modelTypeCards: KanbanCardType[];
};

const DEFAULT_MULTI_LANG_VALUE = {
    ru: '',
    en: '',
};

export const AutomationPlacing = (props: TAutomationPlacingProps) => {
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);
    const principals = useSelector(PrincipalsSelectors.getAll);
    const dispatch = useDispatch();
    const { modelType, serverId, presetId, modelTypeSymbols, modelTypeCards } = props;
    const { placingRules } = modelType.rules;
    const { columns } = modelType.columnsSettings;
    const modelTypeId = modelType.id;
    const objectTypeIds = uniq([
        ...modelTypeSymbols.map(({ objectType }) => objectType),
        ...modelTypeCards.map(({ objectTypeId }) => objectTypeId),
    ]);
    const availableSymbolsCardTypes = [...modelTypeSymbols, ...modelTypeCards];
    const objectTypes = useSelector(ObjectTypeSelectors.byIds({ presetId, serverId, objectTypeIds }));
    const [currentRule, setCurrentRule] = useState<KanbanBoardPlacingRule | undefined>(placingRules?.[0]);

    const getAttributeTypesBySymbolCardTypeId = (symbolCardTypeId: string | undefined): AttributeType[] => {
        const currentSymbolCardType = availableSymbolsCardTypes.find(({ id }) => id === symbolCardTypeId);
        const currentObjectType = objectTypes.find((objectType) => {
            return (
                objectType?.id === (currentSymbolCardType as Symbol)?.objectType ||
                objectType?.id === (currentSymbolCardType as KanbanCardType)?.objectTypeId
            );
        });

        return currentObjectType ? currentObjectType.nodeAttributes : [];
    };

    const attributeTypes = getAttributeTypesBySymbolCardTypeId(currentRule?.symbolCardTypeId).filter(
        (type) => type.valueType !== AttributeValueType.NODE,
    );

    const changePlacingRule = (placingRule: KanbanBoardPlacingRule): void => {
        setCurrentRule(placingRule);
        dispatch(
            changeKanbanModelTypePlacingRule({
                presetId,
                modelTypeId,
                placingRule,
            }),
        );
    };

    const onRuleAdd = () => {
        const newRule = {
            symbolCardTypeId: availableSymbolsCardTypes[0].id,
            searchArea: KanbanBoardPlacingRuleSearchArea.REPOSITORY,
            columnId: columns[0].id,
            id: uuid(),
            conditions: [],
        } as KanbanBoardPlacingRule;

        setCurrentRule(newRule);
        const index = placingRules.findIndex((rule) => rule.id === currentRule?.id);
        dispatch(
            addKanbanModelTypePlacingRule({
                presetId,
                modelTypeId,
                placingRule: newRule,
                index,
            }),
        );
    };

    const onSelectRulesMenu = (val: SelectInfo) => {
        const selectedPlacingRule = placingRules.find((rule) => rule.id === val.key);
        setCurrentRule(selectedPlacingRule);
    };

    const onMoveRule = (isDirectionUp: boolean) => {
        dispatch(
            moveKanbanModelTypePlacingRule({
                presetId,
                placingRuleId: currentRule?.id!,
                modelTypeId,
                isDirectionUp,
            }),
        );
    };

    const onRuleDelete = () => {
        const currentIndex = placingRules.findIndex((rule) => rule.id === currentRule?.id);
        const newIndex = currentIndex === 0 ? 1 : currentIndex - 1;
        dispatch(deleteKanbanModelTypePlacingRule({ presetId, modelTypeId, placingRuleId: currentRule?.id! }));
        setCurrentRule(placingRules[newIndex]);
    };

    const onConditionDelete = (conditionId: string) => {
        const changedPlacingRule = {
            ...currentRule,
            conditions: currentRule?.conditions.filter((condition) => condition.id !== conditionId),
        } as KanbanBoardPlacingRule;

        changePlacingRule(changedPlacingRule);
    };

    const onSymbolCardTypeChange = (id: string) => {
        const changedPlacingRule = {
            ...currentRule,
            symbolCardTypeId: id,
            conditions: [],
        } as KanbanBoardPlacingRule;

        changePlacingRule(changedPlacingRule);
    };

    const onAddFilterCondition = () => {
        const attributeType = attributeTypes[0];
        const attributeValue = getDefaultAttributeValueByType(attributeType);

        const newFilterCondition = {
            id: uuid(),
            attributeTypeId: attributeType.id,
            condition: KanbanBoardPlacingConditionCondition.EQUALS,
            value: attributeValue,
        } as KanbanBoardPlacingCondition;

        const changedPlacingRule = {
            ...currentRule,
            conditions: [...currentRule?.conditions!, newFilterCondition],
        } as KanbanBoardPlacingRule;

        changePlacingRule(changedPlacingRule);
    };

    const onAttributeTypeChange = (conditionId: string, attributeTypeId: string) => {
        const changedPlacingRule = {
            ...currentRule,
            conditions: currentRule?.conditions.map((condition) => {
                if (condition.id === conditionId) {
                    const attributeType = attributeTypes.find((attrType) => attrType.id === attributeTypeId);

                    return { ...condition, attributeTypeId, value: getDefaultAttributeValueByType(attributeType!) };
                }

                return condition;
            }),
        } as KanbanBoardPlacingRule;

        changePlacingRule(changedPlacingRule);
    };

    const onConditionValueChange = (attrValueId: string, valueType: TValueTypeEnum, value: any) => {
        const changedPlacingRule = {
            ...currentRule,
            conditions: currentRule?.conditions.map((condition) => {
                if (condition.value.id === attrValueId) {
                    return { ...condition, value: getUpdatedAttributeValue(condition.value, value) };
                }

                return condition;
            }),
        } as KanbanBoardPlacingRule;

        changePlacingRule(changedPlacingRule);
    };

    const onColumnChange = (id: string) => {
        const changedPlacingRule = {
            ...currentRule,
            columnId: id,
        } as KanbanBoardPlacingRule;

        changePlacingRule(changedPlacingRule);
    };

    const onCopyRule = () => {
        const newRule: KanbanBoardPlacingRule = {
            ...currentRule,
            id: uuid(),
        } as KanbanBoardPlacingRule;

        setCurrentRule(newRule);
        const index = placingRules.findIndex((rule) => rule.id === currentRule?.id);
        dispatch(
            addKanbanModelTypePlacingRule({
                presetId,
                modelTypeId,
                placingRule: newRule,
                index,
            }),
        );
    };

    const getDefaultAttributeValueByType = (attributeType: AttributeType): AttributeValue => {
        let attributeValue = asAttributeValue(attributeType);

        switch (attributeValue.valueType) {
            case AttributeValueType.STRING:
            case AttributeValueType.MULTI_STRING:
                attributeValue = {
                    ...attributeValue,
                    id: uuid(),
                    value: '',
                    str: { ...DEFAULT_MULTI_LANG_VALUE },
                } as AttributeValueString;
                break;
            case AttributeValueType.URL:
                attributeValue = {
                    ...attributeValue,
                    id: uuid(),
                    value: '',
                    url: { ...DEFAULT_MULTI_LANG_VALUE },
                    name: { ...DEFAULT_MULTI_LANG_VALUE },
                } as AttributeValueUrl;
                break;
            case AttributeValueType.PRINCIPAL:
                attributeValue = {
                    ...attributeValue,
                    id: uuid(),
                    value: '',
                    logins: [],
                    groupIds: [],
                } as AttributeValuePrincipal;
                break;
            case AttributeValueType.MULTI_SELECT:
                attributeValue = {
                    ...attributeValue,
                    id: uuid(),
                    value: '',
                    valueIds: [],
                } as AttributeValueMultiSelect;
                break;
            default:
                attributeValue = {
                    ...attributeValue,
                    id: uuid(),
                    value: '',
                };
                break;
        }

        return attributeValue;
    };

    const getUpdatedAttributeValue = (changedAttr: AttributeValue, value: any): AttributeValue => {
        if (changedAttr.valueType === AttributeValueType.URL) {
            const attributeValueUrl = changedAttr as AttributeValueUrl;

            const oldUrl: InternationalString | undefined = attributeValueUrl.url;
            const oldName: InternationalString | undefined = attributeValueUrl.name;
            const newUrl = checkAttributeValues(oldUrl, value, currentLocale);
            const { name } = value as AttributeValueUrl;
            const newName = name ? { ...name } : oldName;

            return {
                ...attributeValueUrl,
                value: '',
                url: typeof value === 'string' ? oldUrl : newUrl,
                name: typeof value === 'string' ? checkAttributeValues(oldName, value, currentLocale) : newName,
            } as AttributeValueUrl;
        }

        if (changedAttr.valueType === AttributeValueType.NODE) {
            const { nodeId, multilingualName, type, name } = value as Node;

            return {
                ...changedAttr,
                value: '',
                linkedNodeId: nodeId?.id,
                name: LocalesService.changeLocaleValue(multilingualName, currentLocale, name),
                nodeType: type,
            } as AttributeValueNode;
        }

        if (changedAttr.valueType === AttributeValueType.BOOLEAN) {
            return {
                ...changedAttr,
                value,
            } as AttributeValue;
        }

        if (
            changedAttr.valueType === AttributeValueType.STRING ||
            changedAttr.valueType === AttributeValueType.MULTI_STRING
        ) {
            if (typeof value === 'string') {
                return {
                    ...changedAttr,
                    value,
                    str: LocalesService.changeLocaleValue(
                        (changedAttr as AttributeValueString).str,
                        currentLocale,
                        value,
                    ),
                } as AttributeValueString;
            }

            return {
                ...changedAttr,
                value: LocalesService.internationalStringToString(value, currentLocale),
                str: value,
            } as AttributeValueString;
        }

        if (changedAttr.valueType === AttributeValueType.MULTI_SELECT) {
            const valueIds = value as string[];

            return {
                ...changedAttr,
                value: '',
                valueIds,
            } as AttributeValueMultiSelect;
        }

        if (
            changedAttr.valueType === AttributeValueType.TIME ||
            changedAttr.valueType === AttributeValueType.DATE ||
            changedAttr.valueType === AttributeValueType.DATE_TIME
        ) {
            const newTime = value as Dayjs | undefined;

            return {
                ...changedAttr,
                value: newTime?.valueOf().toString() ?? '',
            };
        }

        if (changedAttr.valueType === AttributeValueType.PERIOD) {
            const from = momentDateToTimestamp?.(value?.[0]);
            const to = momentDateToTimestamp?.(value?.[1]);
            const dateObj: TPeriodRange | string =
                from && to
                    ? {
                          from: from.toString(),
                          to: to.toString(),
                      }
                    : '';

            return {
                ...changedAttr,
                value: dateObj && JSON.stringify(dateObj),
            };
        }

        if (changedAttr.valueType === AttributeValueType.PRINCIPAL) {
            return {
                ...changedAttr,
                ...value,
            } as AttributeValuePrincipal;
        }

        return {
            ...changedAttr,
            value,
        };
    };

    const attributeValueToComponent = (
        attributeValue: AttributeValue,
        onValueChange: (attrValueId: string, valueType: TValueTypeEnum, value: any) => void,
    ) => {
        const { valueType } = attributeValue;
        const isUrlType = valueType === AttributeValueType.URL || valueType === AttributeValueType.NODE;
        let inputType: TInputTypeEnum = 'text';
        if (valueType === AttributeValueType.NUMERIC || valueType === AttributeValueType.INTEGER) {
            inputType = 'number';
        } else if (valueType === AttributeValueType.MULTI_STRING) {
            inputType = 'textarea';
        }
        const withoutMultiLangBtn: boolean = ![
            AttributeValueType.MULTI_STRING,
            AttributeValueType.STRING,
            AttributeValueType.URL,
        ].includes(valueType as AttributeValueType);
        const onClickLink = () => dispatch(openAttributeLinkAction(attributeValue));

        switch (valueType) {
            case AttributeValueType.STRING:
            case AttributeValueType.MULTI_STRING:
            case AttributeValueType.NUMERIC:
            case AttributeValueType.INTEGER:
            case AttributeValueType.FILE:
            case AttributeValueType.URL:
            case AttributeValueType.JSON: {
                const text = storageValueToString(attributeValue, currentLocale, { attributeTypes }) || '';

                return (
                    <div className={theme.editableTextContainer}>
                        <MultiLangEditableText
                            text={text}
                            className={(valueType === AttributeValueType.URL && theme.linkEditableTextContainer) || ''}
                            disabled={false}
                            onChange={(value: string | InternationalString | TAttributeUrlValues) =>
                                onValueChange(attributeValue.id, valueType, value)
                            }
                            allowEmptyValue
                            isUrlType={isUrlType}
                            inputType={inputType}
                            onClickLink={onClickLink}
                            record={attributeValue}
                            allowMultiLangEmptyValue={attributeValue.valueType === AttributeValueType.STRING}
                            clearSelecting={() => undefined}
                            withoutMultiLang={withoutMultiLangBtn}
                        />
                    </div>
                );
            }
            case AttributeValueType.DATE_TIME:
            case AttributeValueType.DATE: {
                return (
                    <div className={theme.editableElementMedium}>
                        <DatePickerProvider>
                            <DatePicker
                                style={{ width: '100%' }}
                                showTime={valueType === AttributeValueType.DATE_TIME}
                                format={valueType === AttributeValueType.DATE_TIME ? dateTimeFormat : dateFormat}
                                defaultValue={
                                    attributeValue.value ? timestampToMomentDate(+attributeValue.value) : undefined
                                }
                                onChange={(date: dayjs.Dayjs) => {
                                    onValueChange(attributeValue.id, valueType, date);
                                }}
                                getPopupContainer={getAntdPopupContainer}
                            />
                        </DatePickerProvider>
                    </div>
                );
            }
            case AttributeValueType.PERIOD: {
                const periodObj = jsonParse(attributeValue.value) as TPeriodRange | null;
                const defaultRange = [
                    periodObj?.from ? timestampToMomentDate(+periodObj.from) : undefined,
                    periodObj?.to ? timestampToMomentDate(+periodObj.to) : undefined,
                ] as [dayjs.Dayjs, dayjs.Dayjs];

                return (
                    <div className={theme.editableElementLarge}>
                        <DatePickerProvider>
                            <DatePicker.RangePicker
                                style={{ width: '100%' }}
                                className={adminToolTheme.rangePicker}
                                format={dateFormat}
                                defaultValue={defaultRange}
                                ranges={{
                                    [intl.formatMessage(messages.today)]: [dayjs(), dayjs()],
                                    [intl.formatMessage(messages.currentMonth)]: [
                                        dayjs().startOf('month'),
                                        dayjs().endOf('month'),
                                    ],
                                }}
                                onChange={(dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
                                    onValueChange(attributeValue.id, valueType, dates);
                                }}
                                getPopupContainer={getAntdPopupContainer}
                            />
                        </DatePickerProvider>
                    </div>
                );
            }
            case AttributeValueType.BOOLEAN: {
                const isChecked: boolean = checkBooleanValue(attributeValue.value);

                return (
                    <Checkbox
                        status={isChecked}
                        onChange={(value) => onValueChange(attributeValue.id, valueType, value)}
                    />
                );
            }
            case AttributeValueType.TIME: {
                const val = attributeValue.value;

                return (
                    <div className={theme.editableElementMedium}>
                        <DatePickerProvider>
                            <TimePicker
                                style={{ width: '100%' }}
                                defaultValue={val ? timestampToMomentDate(+val) : undefined}
                                onChange={(time: dayjs.Dayjs) => {
                                    onValueChange(attributeValue.id, valueType, time);
                                }}
                                getPopupContainer={getAntdPopupContainer}
                            />
                        </DatePickerProvider>
                    </div>
                );
            }
            case AttributeValueType.SELECT: {
                const attributeType = attributeTypes.find((attrType) => attrType.id === attributeValue.typeId);
                let values = attributeType?.selectPropertyValues;

                const currentValue = storageValueToString(attributeValue, currentLocale as Locale, {
                    system: false,
                    attributeTypes,
                });

                let defaultValue = currentValue || undefined;
                let allowClearSelect = true;

                return (
                    <div className={theme.editableSelect}>
                        <Select
                            defaultValue={defaultValue}
                            onChange={(value) => onValueChange(attributeValue.id, valueType, value)}
                            allowClear={allowClearSelect}
                            getPopupContainer={getAntdPopupContainer}
                        >
                            {values?.map((v) => {
                                return (
                                    <Select.Option key={v.id} value={v.id}>
                                        {LocalesService.internationalStringToString(v.value, currentLocale as Locale)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                );
            }
            case AttributeValueType.MULTI_SELECT: {
                const attributeType = attributeTypes.find((attrType) => attrType.id === attributeValue.typeId);
                const values = attributeType?.selectPropertyValues;
                const currentValueIds = (attributeValue as AttributeValueMultiSelect).valueIds || [];
                const currentActualValueIds = currentValueIds.filter((id) => values?.find((v) => v.id === id));

                return (
                    <div className={theme.editableSelect}>
                        <Select
                            mode="multiple"
                            onChange={(value: string[]) => onValueChange(attributeValue.id, valueType, value)}
                            defaultValue={currentActualValueIds}
                            getPopupContainer={getAntdPopupContainer}
                        >
                            {values?.map((v) => (
                                <Select.Option key={v.id} value={v.id}>
                                    {LocalesService.internationalStringToString(v.value, currentLocale as Locale)}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                );
            }
            case AttributeValueType.PRINCIPAL: {
                const { logins, groupIds } = attributeValue as AttributeValuePrincipal;
                const attributeType = attributeTypes.find((at) => at.id === attributeValue?.typeId);
                const { allSelectedUsers: users } = getUsersSelectedData(principals, logins);
                const { allSelectedGroups: groups } = getGroupsSelectedData(principals, groupIds);

                return (
                    <div className={theme.editableTextContainer}>
                        <div className={theme.editableElementLarge}>
                            <div className={theme.principalAttributeContainer}>
                                {users?.map((user) => (
                                    <UserLogin
                                        text={formatUserName(attributeType?.principalAttributeTypeSettings?.format)(
                                            user,
                                        )}
                                        user={user}
                                        key={`principal-user_${user.login}`}
                                    />
                                ))}
                                {groups?.map((group) => (
                                    <GroupName text={group.login} group={group} key={`principal-group_${group.id}`} />
                                ))}
                            </div>
                        </div>

                        <Button
                            onClick={() => {
                                dispatch(
                                    openDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG, {
                                        serverId,
                                        attributeName: attributeType?.name,
                                        attributeValue,
                                        principalType: attributeType?.principalAttributeTypeSettings?.principalType,
                                        onSubmit: (value: AttributeValuePrincipal) =>
                                            onValueChange(attributeValue.id, valueType, value),
                                    }),
                                );
                            }}
                        >
                            <EditOutlined className={theme.editOutlinedIcon} />
                        </Button>
                    </div>
                );
            }

            default:
                return <span>{intl.formatMessage(messages.unsupportedValueType)}</span>;
        }
    };

    return (
        <div>
            <div style={{ padding: '10px' }}>{intl.formatMessage(messages.definePlacingRules)}</div>

            <div className={theme.flex}>
                <div className={theme.leftSideBar}>
                    <div className={theme.addRule}>
                        <Button
                            size="small"
                            onClick={onRuleAdd}
                            disabled={availableSymbolsCardTypes.length === 0 || columns.length === 0}
                        >
                            {intl.formatMessage(messages.addRule)}
                        </Button>
                    </div>

                    <div className={theme.menu}>
                        <Menu mode="inline" onSelect={onSelectRulesMenu} selectedKeys={[currentRule?.id || '']}>
                            {placingRules.map(({ symbolCardTypeId, columnId, id }) => {
                                const symbol = availableSymbolsCardTypes.find(({ id }) => id === symbolCardTypeId);
                                const symbolName =
                                    get(symbol, 'name') || get(symbol, `multilingualName[${currentLocale}]`);
                                const columnName = columns.find(({ id }) => id === columnId)?.multilingualName[
                                    currentLocale
                                ];

                                return <Menu.Item key={id}>{`${symbolName} (${columnName})`}</Menu.Item>;
                            })}
                        </Menu>
                    </div>
                </div>

                <div className={theme.rightSideBar}>
                    {currentRule ? (
                        <div className={theme.ruleTab}>
                            <div className={theme.ruleControlsContainer}>
                                <Button onClick={onCopyRule} visualStyle="text" icon={icCopy} />
                                <Button
                                    onClick={() => {
                                        onMoveRule(true);
                                    }}
                                    visualStyle="text"
                                    icon={icArrowUp} />
                                <Button
                                    onClick={() => {
                                        onMoveRule(false);
                                    }}
                                    icon={icArrowDown}
                                    visualStyle="text"
                                />
                                <Button onClick={onRuleDelete} visualStyle="text" icon={icDelete} />
                            </div>

                            <div className={theme.flex}>
                                <div className={theme.ruleValueWrapper}>
                                    <div>{intl.formatMessage(messages.symbolType)}</div>
                                    <Select
                                        value={currentRule.symbolCardTypeId || availableSymbolsCardTypes[0].id}
                                        onChange={onSymbolCardTypeChange}
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                    >
                                        {availableSymbolsCardTypes.map((symbol) => (
                                            <Select.Option value={symbol.id} key={symbol.id}>
                                                {symbol.multilingualName
                                                    ? symbol.multilingualName[currentLocale]
                                                    : get(symbol, 'name')}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className={theme.ruleValueWrapper}>
                                    <div>{intl.formatMessage(messages.searchArea)}</div>
                                    <Select
                                        value={currentRule.searchArea}
                                        disabled
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                    >
                                        <Select.Option value={KanbanBoardPlacingRuleSearchArea.REPOSITORY}>
                                            {intl.formatMessage(messages.allDatabase)}
                                        </Select.Option>
                                    </Select>
                                </div>
                            </div>

                            <div className={theme.ruleFilterCondition}>
                                <div className={theme.ruleFilterConditionLable}>
                                    {intl.formatMessage(messages.filterConditionsSummarized)}
                                </div>
                                <Button onClick={onAddFilterCondition} visualStyle="text" disabled={!attributeTypes.length} icon={icAdd} />
                            </div>

                            {currentRule.conditions.map((condition) => (
                                <div className={theme.ruleFilterCondition} key={condition.id}>
                                    <div className={theme.ruleValueWrapper}>
                                        <div>{intl.formatMessage(messages.objectAttribute)}</div>
                                        <Select
                                            value={condition.attributeTypeId || attributeTypes[0].id}
                                            onChange={(attrTypeId) => onAttributeTypeChange(condition.id, attrTypeId)}
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                        >
                                            {attributeTypes.map((attr) => (
                                                <Select.Option value={attr.id} key={attr.id}>
                                                    {attr.multilingualName
                                                        ? attr.multilingualName[currentLocale]
                                                        : attr.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>

                                    <div className={theme.ruleValueWrapper}>
                                        <div>{intl.formatMessage(messages.condition)}</div>
                                        <Select
                                            value={condition.condition}
                                            disabled
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                        >
                                            <Select.Option value={KanbanBoardPlacingConditionCondition.EQUALS}>
                                                {intl.formatMessage(messages.equals)}
                                            </Select.Option>
                                        </Select>
                                    </div>

                                    <div className={theme.ruleValueWrapper} style={{ marginLeft: '15px' }}>
                                        <div>{intl.formatMessage(messages.value)}</div>
                                        {attributeValueToComponent(condition.value, onConditionValueChange)}
                                    </div>

                                    <div className={theme.ruleDeleteButtonWrapper}>
                                        <Button
                                            onClick={() => onConditionDelete(condition.id)}
                                            visualStyle="text"
                                            icon={icConditionDelete}
                                        />
                                    </div>
                                    
                                </div>
                            ))}

                            <div className={theme.ruleValueWrapper}>
                                <div style={{ fontWeight: 'bold' }}>
                                    {intl.formatMessage(messages.placementInColumn)}
                                </div>
                                <Select
                                    value={currentRule.columnId}
                                    onChange={onColumnChange}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                    {columns.map((col) => (
                                        <Select.Option value={col.id} key={col.id}>
                                            {col.multilingualName[currentLocale]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
