import React, { CSSProperties, forwardRef, useId } from 'react';
import classnames from 'classnames';
import theme from './Button.scss';
import { Tooltip } from 'antd';
import { TButtonProps, TTextButtonStyle, TTooltipeSettings } from './Button.types';
import { TooltipPlacement } from 'antd/es/tooltip';
import { GREY_COLOR_MATRIX } from '../constants';

const DEFAULT_SIZE = 16;

/**
 * ссылка на фигму (https://www.figma.com/design/QwNP21XezszK7TasNIoDQf/SDS-Design-KIT?node-id=9-167&t=aUiHP2EDE6s7uZY2-0)
 *
 * @param {'large' | 'normal' | 'small'} size большая, средняя или маленькая кнопка
 * @param {'primary' | 'secondary' | TTextButtonStyle} visualStyle сплошная заливка, простая кнопка без заливки, только текст кнопки без заливки и рамок (можно передать цвет)
 * @param {boolean | undefined} danger кнопка красного цвета
 * @param {React.ReactNode} children текст в кнопке
 * @param {boolean | undefined} disabled дизейбл кнопки
 * @param {string | undefined} key React key кнопки
 * @param {string | undefined} dataTest идентификатор для тестов
 * @param {string | { title: string, position: 'top' | 'bottom' | 'right' | 'left', offset: number } | undefined} tooltip текст подсказки, либо объект с текстом подсказки, смещением и направлением
 * @param {"button" | "submit" | "reset" | undefined} type html тип кнопки
 * @param {boolean | undefined} autoFocus автофокус
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick функция, которая будет вызвана при нажатии на кнопку
 * @param {FocusEventHandler<HTMLButtonElement>} onBlur функция, которая будет вызвана при потере фокуса
 * @param {TSVGIcon | undefined} icon svg иконка с размерами
 * @param {React.KeyboardEventHandler<HTMLButtonElement> | undefined} onKeyDown функция, которая будет вызвана при нажатии клавиши на клавиатуре
 * @param {number | undefined} width ширина конпки в px.
 */

export const Button = forwardRef<HTMLButtonElement, TButtonProps>((props, ref) => {
    const {
        children,
        danger,
        size = 'normal',
        visualStyle = 'secondary',
        disabled,
        key,
        dataTest,
        tooltip,
        type,
        autoFocus,
        onClick,
        onBlur,
        icon,
        width,
        tabIndex,
        onKeyDown,
    } = props;

    const buttonClass: string = classnames({
        [theme.button]: !disabled && visualStyle === 'secondary',
        [theme.buttonFilled]: !disabled && visualStyle === 'primary',
        [theme.textOnly]: !disabled && [(visualStyle as TTextButtonStyle)?.type, visualStyle].includes('text'),
        [theme.buttonDisabled]: disabled && visualStyle === 'secondary',
        [theme.buttonFilledDisabled]: disabled && visualStyle === 'primary',
        [theme.textOnlyDisabled]: disabled && [(visualStyle as TTextButtonStyle)?.type, visualStyle].includes('text'),
        [theme.big]: size === 'large',
        [theme.middle]: size === 'normal',
        [theme.small]: size === 'small',
        [theme.red]: danger,
        [theme.iconButton]: !disabled && !('children' in props),
        [theme.iconButtonDisabled]: disabled && !('children' in props),
        [theme.white]: (visualStyle as TTextButtonStyle)?.color === 'white',
        [theme.grey]: (visualStyle as TTextButtonStyle)?.color === 'grey',
        [theme.iconButton]: !disabled && !('children' in props),
        [theme.iconButtonDisabled]: disabled && !('children' in props),
    });

    const buttonStyle: CSSProperties = { width };
    const id = useId();

    const hasTooltipStyle: boolean = typeof tooltip !== 'string';
    const tooltipPlacement: string =
        hasTooltipStyle && (tooltip as TTooltipeSettings)?.position
            ? (tooltip as TTooltipeSettings).position || 'top'
            : 'top';
    const tooltipOffset: number | undefined = hasTooltipStyle ? (tooltip as TTooltipeSettings)?.offset : undefined;

    const onVisibleChange = (isVisible: boolean) => {
        const element = document.getElementsByClassName(id)[0] as HTMLElement;

        if (element) {
            const tooltipeStyle: CSSStyleDeclaration = element.style;
            tooltipeStyle.top = `${parseFloat(tooltipeStyle.top) + (tooltipOffset || 0)}px`;
            tooltipeStyle.zIndex = isVisible ? '9999' : '-1';
        }
    };

    return (
        <Tooltip
            mouseLeaveDelay={0}
            placement={tooltipPlacement as TooltipPlacement}
            afterOpenChange={onVisibleChange}
            overlayStyle={{ zIndex: tooltipOffset ? '-1' : '9999' }}
            autoAdjustOverflow={false}
            title={hasTooltipStyle ? (tooltip as TTooltipeSettings)?.title : (tooltip as string)}
            overlayClassName={classnames(id, theme.tooltip)}
        >
            <button
                ref={ref}
                tabIndex={disabled ? -1 : tabIndex}
                type={type || 'button'}
                autoFocus={autoFocus}
                style={buttonStyle}
                className={buttonClass}
                key={key}
                onClick={!disabled ? onClick : undefined}
                onBlur={onBlur}
                data-test={dataTest}
                onKeyDown={onKeyDown}
            >
                {icon ? (
                    <svg
                        width={icon?.width || DEFAULT_SIZE}
                        height={icon?.height || DEFAULT_SIZE}
                        viewBox={icon.viewBox}
                    >
                        <defs>
                            <filter id="grayscale">
                                <feColorMatrix type="matrix" values={GREY_COLOR_MATRIX} />
                            </filter>
                        </defs>
                        <use xlinkHref={`#${icon.id}`} filter={disabled ? 'url(#grayscale)' : undefined} />
                    </svg>
                ) : (
                    ''
                )}
                {children}
            </button>
        </Tooltip>
    );
});
