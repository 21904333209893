import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import messages from '../../messages/PrincipalAttributeDialog.messages';
import theme from './PrincipalAttributeDialog.scss';
import GroupListComponent from './GroupList.component';
import UserListComponent from './UserList.component';
import { FilterOptions, TPrincipalAttributeDialogProps } from './PrincipalAttributeDialog.types';
import { DELETED_USER_ID, getPrincipalSelectedData } from '../utils';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TPrincipalAttributeDialogState = {
    searchQuery: string;
    users: PrincipalDescriptor[];
    groups: PrincipalDescriptor[];
    initialUsers: PrincipalDescriptor[];
    initialGroups: PrincipalDescriptor[];
    selectedUsers: PrincipalDescriptor[];
    selectedGroups: PrincipalDescriptor[];
    currentFilter: FilterOptions;
};

const selectItems = [
    { value: FilterOptions.ALL, message: <FormattedMessage {...messages.allUsers} /> },
    { value: FilterOptions.ACTIVE, message: <FormattedMessage {...messages.activeUsers} /> },
    { value: FilterOptions.BLOCKED, message: <FormattedMessage {...messages.blockedUsers} /> },
    { value: FilterOptions.DELETED, message: <FormattedMessage {...messages.deletedUsers} /> },
];

class PrincipalAttributeDialog extends Component<TPrincipalAttributeDialogProps, TPrincipalAttributeDialogState> {
    state: TPrincipalAttributeDialogState = {
        searchQuery: '',
        initialUsers: this.props.users,
        initialGroups: this.props.groups,
        currentFilter: FilterOptions.ALL,
        ...getPrincipalSelectedData(this.props.users, this.props.groups, this.props.attributeValue),
    };

    static getDerivedStateFromProps(props: TPrincipalAttributeDialogProps, state: TPrincipalAttributeDialogState) {
        return props.users !== state.initialUsers || props.groups !== state.initialGroups
            ? getPrincipalSelectedData(props.users, props.groups, props.attributeValue)
            : null;
    }

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== this.state.searchQuery) {
            this.setState({
                searchQuery: e.target.value.trim().toLowerCase(),
            });
        }
    };

    getFilteredGroups = () => {
        const allGroups = this.props.disabled ? this.state.selectedGroups : this.state.groups;

        return allGroups?.filter((item) =>
            [item.login, item.id, item.name, item.lastname, item.middlename]
                .filter((s) => s)
                .map((s) => `${s}`.toLowerCase())
                .some((s) => s.includes(this.state.searchQuery)),
        );
    };

    getFilteredUsers = () => {
        let allUsers = this.props.disabled ? this.state.selectedUsers : this.state.users;
        switch (this.state.currentFilter) {
            case FilterOptions.ACTIVE:
                allUsers = allUsers.filter((user) => !user.blocked && user.id !== DELETED_USER_ID);
                break;
            case FilterOptions.BLOCKED:
                allUsers = allUsers.filter((user) => user.blocked && user.id !== DELETED_USER_ID);
                break;
            case FilterOptions.DELETED:
                allUsers = allUsers.filter((user) => user.id === DELETED_USER_ID);
                break;
        }
        return allUsers?.filter((item) =>
            [item.login, item.id, item.name, item.lastname, item.middlename]
                .filter((s) => s)
                .map((s) => `${s}`.toLowerCase())
                .some((s) => s.includes(this.state.searchQuery)),
        );
    };

    handleSelectGroups = (selectedGroups: number[]) => {
        this.setState((prevState) => ({
            selectedGroups: prevState.groups?.filter((el) => selectedGroups?.some((groupId) => groupId === el.id)),
        }));
    };

    handleSelectUsers = (selectedUsers: string[]) => {
        this.setState((prevState) => ({
            selectedUsers: prevState.users?.filter((el) => selectedUsers?.some((userLogin) => userLogin === el.login)),
        }));
    };

    handleSubmit = () => {
        this.props.onSubmit({
            ...this.props.attributeValue,
            logins: this.state.selectedUsers.map((el: PrincipalDescriptor) => el.login),
            groupIds: this.state.selectedGroups.map((el: PrincipalDescriptor) => el.id),
        });
    };

    setFilter = (newFilter: FilterOptions) => {
        this.setState({
            currentFilter: newFilter,
        });
    };

    render() {
        const { open, attributeName, onCancel, disabled, intl, principalType = "USERS_AND_GROUPS" } = this.props;
        const { selectedUsers, selectedGroups } = this.state;

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onCancel,
                        value: intl.formatMessage(messages.cancelButton),
                        dataTest: 'permission-dialog_cancel-button',
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.saveButton),
                        visualStyle: 'primary',
                        dataTest: 'permission-dialog_confirm-button',
                        disabled: disabled,
                    },
                ]}
            />
        );

        return (
            <Dialog open={open} width="840px" footer={disabled ? null : footer} onCancel={onCancel}>
                <div className={theme.inputHeaderContainer}>
                    <Row gutter={16}>
                        <Col span={8}>
                            {attributeName && (
                                <div className={theme.attributeName}>
                                    <b>{attributeName}</b>
                                </div>
                            )}
                        </Col>
                        <Col span={6}>
                            <Input suffix={<SearchOutlined />} onChange={this.handleSearch} style={{ width: 200 }} />
                        </Col>
                        <Col span={6}>
                            <Select
                                onChange={this.setFilter}
                                defaultValue={FilterOptions.ALL}
                                style={{ width: 200, marginLeft: '10px' }}
                            >
                                {selectItems.map((item) => (
                                    <Select.Option key={item.value} value={item.value}>
                                        {item.message}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </div>
                <Row gutter={16} style={{ marginTop: 10 }}>
                    <Col span={8}>
                        <GroupListComponent
                            data={this.getFilteredGroups()}
                            selected={selectedGroups.map((el: PrincipalDescriptor) => el.id)}
                            onSelectChange={this.handleSelectGroups}
                            readOnly={disabled || !(["GROUP" , "USERS_AND_GROUPS"]).includes(principalType)}
                        />
                    </Col>
                    <Col span={16}>
                        <UserListComponent
                            data={this.getFilteredUsers()}
                            selected={selectedUsers.map((el: PrincipalDescriptor) => el.login)}
                            onSelectChange={this.handleSelectUsers}
                            readOnly={disabled || !(["USER" , "USERS_AND_GROUPS"]).includes(principalType)}
                        />
                    </Col>
                </Row>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(PrincipalAttributeDialog);

export { IntlComponent as PrincipalAttributeDialog };
