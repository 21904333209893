import React from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import UserLoginComponent from '../../../ObjectPropertiesDialog/components/PrincipalAttributeType/UserLogin.component';
import GroupNameComponent from '../../../ObjectPropertiesDialog/components/PrincipalAttributeType/GroupName.component';
import { AttributeType, AttributeValuePrincipal, PrincipalDescriptor } from '../../../../serverapi/api';
import { PrincipalType } from '../../../../models/security/PrincipalType';
import {
    formatUserName,
    getGroupsSelectedData,
    getUsersSelectedData,
} from '../../../ObjectPropertiesDialog/components/utils';
import theme from './NavigatorProperties.scss';

type TPrincipalAttributeValueProps = {
    title: string;
    attributeValue: AttributeValuePrincipal;
    attributeTypes: AttributeType[];
    principals: PrincipalDescriptor[];
    serverId: string;
};

export default ({ title, attributeValue, attributeTypes, principals, serverId }: TPrincipalAttributeValueProps) => {
    const dispatch = useDispatch();

    const { logins, groupIds } = attributeValue;
    const principalAttributeTypeSettings = attributeTypes?.find(
        (at) => at.id === attributeValue?.typeId,
    )?.principalAttributeTypeSettings;
    const { allSelectedUsers } = getUsersSelectedData(principals, logins);
    const { allSelectedGroups } = getGroupsSelectedData(principals, groupIds);
    const principalsCount = (logins?.length || 0) + (groupIds?.length || 0);
    const participants = [...allSelectedUsers, ...allSelectedGroups].slice(0, 5).map((participant) => {
        const text =
            participant.principalType === PrincipalType.USER
                ? formatUserName(principalAttributeTypeSettings?.format)(participant)
                : participant.login;
        const element =
            participant.principalType === PrincipalType.USER ? (
                <UserLoginComponent text={text} user={participant} key={`principal-user_${participant.login}`} />
            ) : (
                <GroupNameComponent text={text} group={participant} key={`principal-group_${participant.id}`} />
            );

        return element;
    });

    return (
        <div>
            {participants}
            {principalsCount > 5 && (
                <>
                    ...
                    <div
                        className={theme.linkWrapper}
                        onClick={() => {
                            dispatch(
                                openDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG, {
                                    serverId,
                                    attributeName: title,
                                    attributeValue,
                                    principalType: principalAttributeTypeSettings?.principalType,
                                    disabled: true,
                                }),
                            );
                        }}
                    >
                        +{principalsCount - 5}
                    </div>
                </>
            )}
        </div>
    );
};
